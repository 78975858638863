import "./welcome-screen.css";

const WelcomeScreen = () => {
  return <div>
    <h1 className="welcome-heading">Welcome, Toye!</h1>
    <h3 className="sub-heading">To begin, connect your data sources and build your first segment for targeted marketing efforts.</h3>
    </div>
}

export default WelcomeScreen;
